import React, { Fragment } from "react";
// import { Styled } from "theme-ui";

/**
 * Change the content to add your own bio
 */

export default () => (
  <Fragment>
    multiple-model agnostic, writes code, mostly javascript, old school film,
    tv, sci-fi & comics nerd, boosting the good stuff hopefully
  </Fragment>
);
